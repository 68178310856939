<template>
    <div class="dashboard-questions bg-greyblue pb-50px pt-70px">
      <div class="container" v-if="loading == true">
        <span class="page-seperator mb-85px"></span>
        <div class="flex items-center justify-center pt-25px pb-25px bg-white text-black rounded lg:w-2/12">
            <p class="text-med font-semibold">Trends</p>
        </div>
        <div class="flex flex-wrap items-center py-50px rounded bg-white">
          <div class="w-full lg:w-half mb-12 xl:mb-0 px-4">
            <TrendTableLineChart :chartData="tables[0]" />
          </div>
          <div class="w-full lg:w-half mb-12 xl:mb-0 px-4">
            <TrendTableLineChartRPE :chartData="tables[1]" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import axios from "axios";
  import firebase from "firebase";
  import store from "./../../store";
  import TrendTableLineChart from "@/views/cards/TrendTableLineChartGrossMarginLeadership.vue";
  import TrendTableLineChartRPE from "@/views/cards/TrendTableLineChartRpeLeadership.vue";
  
  export default {
    name: "DashFinance",
  
    components: {
        TrendTableLineChart,
        TrendTableLineChartRPE,
    },
  
    data() {
      return {
        tables: [],
        loading: false,
      };
    },
  
    created() {
      firebase.auth().onAuthStateChanged(user => {
        store.dispatch("fetchUser", user);
  
        axios.defaults.headers = {
          'Content-Type': 'application/json',
          Authorization: user.za
        };
  
        let endpoints = [
          'https://pdai-api-7uc2rb5qma-nw.a.run.app/statistics/trends/1'
        ];
  
        Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
          .then(([
            {
              data: tables
            },
          ]) => {
            this.tables = tables.tables;
            this.loading = true;
          });
      });
    },

  };
  </script>
  